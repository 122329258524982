const state = () => ({
  applicant_information: {
     loan_id: "",
     stage: "",
     name: "",
     nrc_state_id: "",
     nrc_state_number: "",
     nrc_state_burmese_number: "",
     nrc_district_id: "",
     nrc_district_name: "",
     nrc_district_burmese_name: "",
     nrc_type_id: "",
     nrc_type_name: "",
     nrc_type_burmese_name: "",
     nrc_number: "",
     father_name: "",
     phone_number: "",
     other_phone_number: "",
     email: "",
     date_of_birth: "",
     gender: "",
     marital_status: "",
     has_bank_account: 1,
     country_state_id: "",
     city_id: "",
     township_id: "",
     place_type: "Ward",
     ward_or_village_name: "",
     residence_type: "",
     living_with: "",
     years_of_stay: "",
     months_of_stay: "",
     street: "",
     building_number: "",
     floor: "",
     emergency_contact_name: "",
     emergency_contact_phone_number: "",
  },
  emergency: {
     country_state_id: "",
     city_id: "",
     township_id: "",
     place_type: "Ward",
     ward_or_village_name: "",
     street: "",
     building_number: "",
     living_with : "",
     floor: ""
  },
});

const getters = {
  getApplicantData(state) {
     return state.applicant_information;
  },

  getEmergencyData(state) {
     return state.emergency;
  },
};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) =>
     (state.applicant_information.loan_id = loan_id),

  applicantDetails: (state, responseData) => {
        state.applicant_information.stage = responseData.loan?.stage;
        state.applicant_information.nrc_state_id = responseData.nrc?.state_id;
        state.applicant_information.nrc_state_number = responseData.nrc?.state_number;
        state.applicant_information.nrc_state_burmese_number =  responseData.nrc?.state_burmese_number;
        state.applicant_information.nrc_district_id = responseData.nrc?.district_id;
        state.applicant_information.nrc_district_name = responseData.nrc?.district_name;
        state.applicant_information.nrc_district_burmese_name = responseData.nrc?.district_burmese_name;
        state.applicant_information.nrc_type_id =  responseData.nrc?.type_id;
        state.applicant_information.nrc_type_name = responseData.nrc?.type_name;
        state.applicant_information.nrc_type_burmese_name = responseData.nrc?.type_burmese_name;
        state.applicant_information.nrc_number = responseData.nrc_number;
        state.applicant_information.father_name = responseData.father_name;
        state.applicant_information.date_of_birth = responseData.date_of_birth;
        state.applicant_information.name = responseData.name;
        state.applicant_information.phone_number = responseData.phone_number;
        state.applicant_information.other_phone_number = responseData.other_phone_number;
        state.applicant_information.email = responseData.email;
        state.applicant_information.gender = responseData.gender;
        state.applicant_information.marital_status = responseData.marital_status;
        state.applicant_information.has_bank_account = responseData.has_bank_account;
        state.applicant_information.country_state_id = responseData.permanent_address?.country_state_id;
        state.applicant_information.city_id = responseData.permanent_address?.city_id;
        state.applicant_information.township_id = responseData.permanent_address?.township_id;
        state.applicant_information.place_type = responseData.permanent_address?.place_type;
        state.applicant_information.ward_or_village_name = responseData.permanent_address?.ward_or_village_name;
        state.applicant_information.residence_type = responseData.permanent_address?.residence_type;
        state.applicant_information.living_with = responseData.permanent_address?.living_with;
        state.applicant_information.years_of_stay = responseData.permanent_address?.years_of_stay;
        state.applicant_information.months_of_stay = responseData.permanent_address?.months_of_stay;
        state.applicant_information.street = responseData.permanent_address?.street;
        state.applicant_information.building_number = responseData.permanent_address?.building_number;
        state.applicant_information.floor = responseData.permanent_address?.floor;
        state.applicant_information.emergency_contact_name = responseData.emergency_contact_name;
        state.applicant_information.emergency_contact_phone_number = responseData.emergency_contact_phone_number;
        state.emergency.country_state_id = responseData.emergency_address?.country_state_id;
        state.emergency.city_id = responseData.emergency_address?.city_id;
        state.emergency.township_id = responseData.emergency_address?.township_id;
        state.emergency.place_type = responseData.emergency_address?.place_type;
        state.emergency.ward_or_village_name = responseData.emergency_address?.ward_or_village_name;
        state.emergency.street = responseData.emergency_address?.street;
        state.emergency.building_number = responseData.emergency_address?.building_number;
        state.emergency.floor = responseData.emergency_address?.floor;
        state.emergency.living_with = responseData.emergency_address?.living_with;
  },

  addPersonalStateData: (state, payload) => {
     state.applicant_information.nrc_state_id = payload?.id;
     state.applicant_information.nrc_state_burmese_number =
        payload.burmese_code;
     state.applicant_information.nrc_state_number = payload.code;
  },

  addPersonalDistrictData: (state, payload) => {
     state.applicant_information.nrc_district_id = payload?.id;
     state.applicant_information.nrc_district_burmese_name =
        payload.burmese_code;
     state.applicant_information.nrc_district_name = payload.code;
  },

  addPersonalNRCtypeData: (state, payload) => {
     state.applicant_information.nrc_type_id = payload?.id;
     state.applicant_information.nrc_type_burmese_name =
        payload.burmese_name;
     state.applicant_information.nrc_type_name = payload.name;
  },

  addAddressRegionData: (state, payload) => {
     state.applicant_information.country_state_id = payload?.id;
  },
  addAddressCityData: (state, payload) => {
     state.applicant_information.city_id = payload?.id;
  },
  addAddressTownshipData: (state, payload) => {
     state.applicant_information.township_id = payload?.id;
  },

  addEmergencyRegionData: (state, payload) => {
     state.emergency.country_state_id = payload?.id;
  },
  addEmergencyCityData: (state, payload) => {
     state.emergency.city_id = payload?.id;
  },
  addEmergencyTownshipData: (state, payload) => {
     state.emergency.township_id = payload?.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};