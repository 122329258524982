export default {
  sme_document: "အသေးစားနှင့်အလတ်စား စီးပွားရေးလုပ်ငန်းချေးငွေစာရွက်စာတမ်းများ",
  total_finish: "ပြီးမြောက်မှု",
  fullfill_data: "လိုအပ်သောအချက်အလက်များဖြည့်ပါ",
  applicant_information: "‌လျှောက်ထားသူ၏ အချက်အလက်များ",
  occupation_information: "အလုပ်အကိုင်အချက်အလက်များ",
  guarantor1_information: "အာမခံလူပုဂ္ဂိုလ် ၁ အချက်အလက်များ",
  guarantor2_information: "အာမခံလူပုဂ္ဂိုလ် ၂ အချက်အလက်များ",
  upload_document: "ငွေချေးသူ၏ စာရွက်စာတမ်းများတင်ရန်",
  applicant_title: "လျောက်ထားသူအချက်အလက်",
  need_to_update: "ပြင်ဆင်ရန်လိုအပ်သည်",
  customer_name: "ငွေချေးသူအမည်",
  nrc: "နိုင်ငံသားစိစစ်ရေးကဒ်ပြားအမှတ်",
  father_name: "အဖအမည်",
  phone_number: "ဖုန်းနံပါတ်",
  other_phone_number: "အခြားဖုန်းနံပါတ်",
  email: "အီးမေးလ်",
  date_of_birth: "မွေးသက္ကရာဇ်",
  nationality: "နိုင်ငံသား",
  Myanmar: "မြန်မာ",
  Other: "အခြား",
  gender: "ကျား/မ",
  Male: "ကျား",
  Female: "မ",
  marital_status: "အိမ်ထောင်",
  Single: "မရှိ",
  Widowed: "မုဆိုးဖို/မ",
  Married: "ရှိ",
  Divorced: "ကွာရှင်း",
  bank_account: "ဘဏ်အကောင့်ရှိပါသလား",
  Yes: "ရှိ",
  No: "မရှိ",
  permanent_address: "အမြဲတမ်းနေရပ်လိပ်စာအချက်အလက်များ",
  region: "တိုင်း /ဒေသ",
  city: "မြို့တော်",
  township: "မြို့နယ်",
  ward_or_village: "ရပ်ကွက် / ကျေးရွာ",
  Ward: "ရပ်ကွက်",
  Village: "ကျေးရွာ",
  street_address: "လမ်းလိပ်စာ",
  building_no: "တိုက်အမှတ်",
  floor: "အလွှာ",
  type_of_residence: "နေထိုင်မှုအမျိုးအစား",
  Owner: "ကိုယ်ပိုင်",
  Rental: "အငှား",
  Parental: "မိဘအိမ်",
  Hostel: "အဆောင်",
  living_with: "အတူတကွနေထိုင်ခြင်း",
  Parent: "မိဘ",
  Offspring: "အမျိုးအနွယ်",
  Spouse: "အိမ်ထောင်ဖက်",
  Friend: "သူငယ်ချင်း",
  Sibling: "မွေးချင်းမောင်နှမ",
  Relative: "အမျိုး",
  Alone: "တစ်ယောက်တည်း",
  year_of_stay: "နေထိုင်သည့်နှစ်",
  year: "နှစ်",
  month: "လ",
  emergency_contact: "ဆက်သွယ်ရန်မိသားစု အချက်အလက်",
  contact_name: "ဆက်သွယ်ရန်အမည်",
  address_of_contact: "ဆက်သွယ်ရမည့်မိသားစုနေရပ်လိပ်စာ",
  relationship_with_applicant: "ချေးငွေေလျှောက်သူနှင့်တော်စပ်ပုံ",
  "Offspring (Daughter/Son)": "မွေးချင်းမောင်နှမ (သမီး/သား)",
  back: "နောက်သို့",
  next: "ရှေ့သို့",
  submit: "တင်ပြပါ",
  done: "ပြီးပါပြီ",
  apply: "အတည်ပြုမည်",
  save_apply_information: "အချက်အလက်များအားသိမ်းဆည်းခြင်း",
  confirmation: "အတည်ပြုချက်ကို စောင့်ပါ။",
  save_information: "အချက်အလက်များအားသိမ်းဆည်းခြင်း",
  successfully_saved: "သင်၏အချက်အလက်များကိုအောင်မြင်စွာ သိမ်းဆည်းပြီးပါပြီ",
  want_exit: "ထွက်ရန်သေချာပါသလား",
  yes: "ထွက်မယ်",
  no: "မထွက်ပါ",
  is_required: "လိုအပ်ပါသည်",
  correct_phone_number: "ဖုန်းနံပါတ်အမှန်ထည့်ပါ",
  no_results: "ရလဒ်မရှိပါ",
  greater_than: "၁၁ ထက်ပိုကြီးလို့မရပါ",
  correct_month: "ကျေးဇူးပြု၍ မှန်ကန်သော လကို ထည့်ပါ",
  correct_salary_date: "ကျေးဇူးပြု၍ မှန်ကန်သော Salary Date ကို ထည့်ပါ",
  correct_email: "ကျေးဇူးပြု၍ မှန်ကန်သော Email ကို ထည့်ပါ",
  correct_nrc: "ကျေးဇူးပြု၍ မှန်ကန်သော NRC Number ကို ထည့်ပါ",
  enter_nrc: "NRC နံပါတ်ထည့်ပါ",
  enter_validate_nrc: "ကျေးဇူးပြု၍ တရားဝင် NRC နံပါတ်ကို ထည့်ပါ",
  occupation_title: "အလုပ်အကိုင်အချက်အလက်",
  occupation: "အလုပ်အကိုင်",
  occupation_name: "အလုပ်အကိုင်အမည်",
  "Company Staff": "ကုမ္ပဏီဝန်ထမ်း",
  "Government Staff": "အစိုးရဝန်ထမ်း",
  "Own Business": "ကိုယ်ပိုင်လုပ်ငန်း",
  occupation_address: "အလုပ်အကိုင်လိပ်စာအချက်အလက်",
  occupation_phone: "အလုပ်အကိုင် ဖုန်းနံပါတ်",
  hr_name: "HR အမည်",
  hr_phone: "HR ဖုန်းနံပါတ်",
  company_status: "ကုမ္ပဏီ အခြေအနေ",
  "Public Company": "အများပိုင် ကုမ္ပဏီ",
  "Private Company": "ပုဂ္ဂလိက ကုမ္ပဏီ",
  Factory: "စက်ရုံ",
  SME: "SME",
  NGO: "NGO",
  "Government Office": "အစိုးရရုံး",
  Military: "စစ်ရေးရုံး",
  Police: "ရဲ",
  occupation_personal_information: "မိမိအလုပ်အကိုင်အချက်အလက်",
  position: "ရာထူး",
  department: "ဌာန",
  department_name: "ဌာနအမည်",
  year_of_service: "လုပ်သက်",
  monthly_basic_income: "လစဉ်ဝင်ငွေ",
  additional_income: "အခြား၀င်ငွေ",
  total_income: "စုစုပေါင်း၀င်ငွေ",
  salary_date: "လစာထုတ်သည့်ရက်",
  guarantor1_title: "အာမခံသူ ၁ ၏အချက်အလက်",
  guarantor_personal_information: "အာမခံသူ၏အချက်အလက်",
  relationship_with_customer: "တော်စပ်ပုံ",
  guarantor_name: "အာမခံအမည်",
  guarantor_permanent_address: "အာမခံသူ၏အမြဲတမ်းနေရပ်လိပ်စာ",
  guarantor_current_address: "အာမခံသူ၏လက်ရှိနေရပ်လိပ်စာ",
  guarantor_company_information: "ကုမ္ပဏီ အချက်အလက်များ",
  guarantor_company_address: "ကုမ္ပဏီ လိပ်စာ",
  guarantor2_title: "အာမခံသူ ၂ ၏အချက်အလက်",
  company_name: "ကုမ္ပဏီ အမည်",
  nrc_front: "နိုင်ငံသားမှတ်ပုံတင်မူရင်းအရှေ့",
  nrc_back: "နိုင်ငံသားမှတ်ပုံတင်မူရင်းအနောက်",
  census_front: "သန်းခေါင်စာရင်း မူရင်းအရှေ့",
  census_back: "သန်းခေါင်စာရင်း မူရင်းအနောက်",
  employee_card: "ဝန်ထမ်းကဒ် (သိုမဟုတ်) လုပ်ငန်းအခြေအနေ",
  selfie_photo: "ဓါတ်ပုံ",
  electricity_bill: "မီတာဘေလ် (သို့မဟုတ်) မြို့တော်ကောင်စီအခွန်",
  ward_recommendation_letter: "ရပ်ကွက်ထောက်ခံစာ",
  income_status: "၃လနောက်ဆုံးဝင်ငွေအခြေအနေ",
  tax_document: "ကိုယ်ပိုင်လုပ်ငန်း၏အထူးခွန် စာရွက်စာတမ်း",
  business_status: "လုပ်ငန်းလိုင်စင်/လုပ်ငန်းအခြေအနေ",
  original_bill: "နောက်ဆုံးလအတွက်မီတာအခွန်မူရင်း",
  loan_amount: "ငွေပမာဏ",
  tenor: "ကြာချိန်",
  reason: "အကြောင်းပြချက်",
  accept_teams_and_conditions: `Win Finance ၏ စည်းမျဉ်းစည်းကမ်းများကို လက်ခံပါသည်။`,
  experience_salary_position_must_be_included:
    "လုပ်သက်၊ လစာ၊ ရာထူးပါဝင်ရပါမည်။",
  recent_bill_as_proof_address:
    "လိပ်စာအထောက်အထားအတွက် လက်တလောအခွန်ဆောင်ထားသော ပြေစာ",
};
