const state = () => ({
   upload_document: {
      loan_id: "",
      stage: "",
      applicant: {
         remark: "",
         nrc_front: "",
         nrc_back: "",
         census_front: "",
         census_back: "",
         selfie_photo: "",
         income_status: "",
         bill_photo: "",
         tax_document: "",
         ward_recommendation_letter: "",
         business_license: "",
         type: "applicant-document",
         completed_percent: 0,
      },
      guarantor_family: {
         nrc_front: "",
         nrc_back: "",
         census_front: "",
         census_back: "",
         selfie_photo: "",
         employee_card: "",
         bill_photo: "",
         type: "guarantor-family-document",
         completed_percent: 0,
      },
      guarantor_colleague: {
         nrc_front: "",
         nrc_back: "",
         census_front: "",
         census_back: "",
         selfie_photo: "",
         bill_photo: "",
         employee_card: "",
         type: "guarantor-colleague-document",
         completed_percent: 0,
      },
      plan: {
         loan_amount: 0,
         tenor: '',
      }
   },
});

const getters = {};
const actions = {};
const mutations = {

   updateLoanID: (state, loan_id) => (state.upload_document.loan_id = loan_id),

   documentDetails: (state, responseData) => {
      console.log('Loan', responseData);
      state.upload_document.stage = responseData?.stage;
      // Bind New Field
      state.upload_document.plan.loan_amount = responseData?.plan.unit_price;
      state.upload_document.plan.tenor = responseData?.plan.tenure;
      state.upload_document.applicant.remark = responseData?.remark;
      state.upload_document.applicant.nrc_front = responseData.applicant?.nrc_front;
      state.upload_document.applicant.nrc_back = responseData.applicant?.nrc_back;
      state.upload_document.applicant.census_front = responseData.applicant?.census_front;
      state.upload_document.applicant.census_back = responseData.applicant?.census_back;
      state.upload_document.applicant.selfie_photo = responseData.applicant?.selfie_photo;
      state.upload_document.applicant.income_status = responseData.applicant?.income_status;
      state.upload_document.applicant.bill_photo = responseData.applicant?.bill_photo;
      state.upload_document.applicant.tax_document = responseData.applicant?.tax_document;

      // Bind New Field
      state.upload_document.applicant.ward_recommendation_letter = responseData.applicant?.ward_recommendation_letter;

      // Bind New Field
      state.upload_document.applicant.business_license = responseData.applicant?.business_license;
      state.upload_document.applicant.completed_percent = responseData.applicant?.completed_percent;
      state.upload_document.guarantor_family.nrc_front = responseData.guarantor_family?.nrc_front;
      state.upload_document.guarantor_family.nrc_back = responseData.guarantor_family?.nrc_back;
      state.upload_document.guarantor_family.census_front = responseData.guarantor_family?.census_front;

      // Bind New Field
      state.upload_document.guarantor_family.census_back = responseData.guarantor_family?.census_back;
      state.upload_document.guarantor_family.selfie_photo = responseData.guarantor_family?.selfie_photo;
      state.upload_document.guarantor_family.employee_card = responseData.guarantor_family?.employee_card;
      state.upload_document.guarantor_family.bill_photo = responseData.guarantor_family?.bill_photo;
      state.upload_document.guarantor_family.completed_percent = responseData.guarantor_family?.completed_percent;
      state.upload_document.guarantor_colleague.nrc_front = responseData.guarantor_colleague?.nrc_front;
      state.upload_document.guarantor_colleague.nrc_back = responseData.guarantor_colleague?.nrc_back;
      state.upload_document.guarantor_colleague.census_front = responseData.guarantor_colleague?.census_front;

      // Bind New Field
      state.upload_document.guarantor_colleague.census_back = responseData.guarantor_colleague?.census_back;
      
      state.upload_document.guarantor_colleague.selfie_photo = responseData.guarantor_colleague?.selfie_photo;
      
      state.upload_document.guarantor_colleague.bill_photo = responseData.guarantor_colleague?.bill_photo;
      state.upload_document.guarantor_colleague.employee_card = responseData.guarantor_colleague?.employee_card;

      state.upload_document.guarantor_colleague.completed_percent = responseData.guarantor_colleague?.completed_percent;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
