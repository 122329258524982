const state = () => ({
  guarantor_colleague: {
    loan_id: "",
    stage: "",
    loan_type: "sme",
    relationship_with_applicant: "",
    name: "",
    phone_number: "",
    date_of_birth: "",
    nrc_state_id: "",
    nrc_state_number: "",
    nrc_state_burmese_number: "",
    nrc_district_id: "",
    nrc_district_name: "",
    nrc_district_burmese_name: "",
    nrc_type_id: "",
    nrc_type_name: "",
    nrc_type_burmese_name: "",
    nrc_number: "",
    gender: "",
    marital_status: "",
    country_state_id: "",
    city_id: "",
    township_id: "",
    place_type: "Ward",
    ward_or_village_name: "",
    street: "",
    building_number: "",
    floor: "",
    residence_type: "",
    living_with: "",
    years_of_stay: "",
    months_of_stay: "",
    occupation: "",
    own_business_name: "",
    position: "",
    years_of_service: "",
    months_of_service: "",
    monthly_basic_income: 0,
    additional_income: 0,
    total_income: 0
  },
  company_address: {
    country_state_id: "",
    city_id: "",
    township_id: "",
    place_type: "Ward",
    ward_or_village_name: "",
    street: "",
    building_number: "",
    floor: "",
  },
});

const getters = {
  getGuarantorColleagueData(state) {
    return state.guarantor_colleague;
  },
  getCompanyData(state) {
    return state.company_address;
  },
};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) =>
    (state.guarantor_colleague.loan_id = loan_id),

  colleagueDetails: (state, responseData) => {

    state.guarantor_colleague = {
      loan_id: responseData.loan_id,
      stage: responseData.stage,
      loan_type: "sme",
      relationship_with_applicant: responseData.relationship_with_applicant,
      name: responseData.name,
      phone_number: responseData.phone_number,
      date_of_birth: responseData.date_of_birth,
      nrc_state_id: responseData.nrc?.state_id,
      nrc_state_number: responseData.nrc?.state_number,
      nrc_state_burmese_number: responseData.nrc?.state_burmese_number,
      nrc_district_id: responseData.nrc?.district_id,
      nrc_district_name: responseData.nrc?.district_name,
      nrc_district_burmese_name: responseData.nrc?.district_burmese_name,
      nrc_type_id: responseData.nrc?.type_id,
      nrc_type_name: responseData.nrc?.type_name,
      nrc_type_burmese_name: responseData.nrc?.type_burmese_name,
      nrc_number: responseData.nrc_number,
      gender: responseData.gender,
      marital_status: responseData.marital_status,
      country_state_id: responseData.permanent_address?.country_state_id,
      city_id: responseData.permanent_address?.city_id,
      township_id: responseData.permanent_address?.township_id,
      place_type: responseData.permanent_address?.place_type,
      ward_or_village_name: responseData.permanent_address?.ward_or_village_name,
      street: responseData.permanent_address?.street,
      building_number: responseData.permanent_address?.building_number,
      floor: responseData.permanent_address?.floor,
      residence_type: responseData.permanent_address?.residence_type,
      living_with: responseData.permanent_address?.living_with,
      years_of_stay: responseData.permanent_address?.years_of_stay,
      months_of_stay: responseData.permanent_address?.months_of_stay,
      occupation: responseData.occupation,
      own_business_name: responseData.own_business_name,
      position: responseData.position,
      years_of_service: responseData.years_of_service,
      months_of_service: responseData.months_of_service,
      monthly_basic_income: responseData.monthly_basic_income,
      additional_income: responseData.additional_income,
      total_income: responseData.total_income,
      completed_percent: 0,
    };
    
    state.company_address = {
      country_state_id: responseData.company_address?.country_state_id,
      city_id: responseData.company_address?.city_id,
      township_id: responseData.company_address?.township_id,
      place_type: responseData.company_address?.place_type,
      ward_or_village_name: responseData.company_address?.ward_or_village_name,
      street: responseData.company_address?.street,
      building_number: responseData.company_address?.building_number,
      floor: responseData.company_address?.floor,
    };

  },

  addPersonalStateData: (state, payload) => {
    state.guarantor_colleague.nrc_state_id = payload?.id;
    state.guarantor_colleague.nrc_state_burmese_number = payload.burmese_code;
    state.guarantor_colleague.nrc_state_number = payload.code;
  },
  addPersonalDistrictData: (state, payload) => {
    state.guarantor_colleague.nrc_district_id = payload?.id;
    state.guarantor_colleague.nrc_district_burmese_name = payload.burmese_code;
    state.guarantor_colleague.nrc_district_name = payload.code;
  },
  addPersonalNRCtypeData: (state, payload) => {
    state.guarantor_colleague.nrc_type_id = payload?.id;
    state.guarantor_colleague.nrc_type_burmese_name = payload.burmese_name;
    state.guarantor_colleague.nrc_type_name = payload.name;
  },

  addPermanentAddressRegionData: (state, payload) => {
    state.guarantor_colleague.country_state_id = payload?.id;
  },
  addPermanentAddressCityData: (state, payload) => {
    state.guarantor_colleague.city_id = payload?.id;
  },
  addPermanentAddressTownshipData: (state, payload) => {
    state.guarantor_colleague.township_id = payload?.id;
  },
  
  addCompanyRegionData: (state, payload) => {
    state.company_address.country_state_id = payload?.id;
  },
  addCompanyCityData: (state, payload) => {
    state.company_address.city_id = payload?.id;
  },
  addCompanyTownshipData: (state, payload) => {
    state.company_address.township_id = payload?.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
