const state = () => ({
   occupation_information: {
      "stage" : "",
      "own_business_name": "",
      "position" : "",
      "occupation_phone" : "",
      "years_of_service" : "",
      "months_of_service" : "",
      "monthly_basic_income" : 0,
      "additional_income" : 0,
      "total_income" : 0,
   },
   company_address: {
      "country_state_id": "",
      "city_id": "",
      "township_id": "",
      "place_type" : "Ward",
      "ward_or_village_name" : "",
      "street": "",
      "building_number": "",
      "floor": ""
   },
});

const getters = {
   getOccupationData(state) {
      return state.occupation_information;
   },

   getCompanyData(state) {
      return state.company_address;
   },
};
const actions = {};
const mutations = {
   updateLoanID: (state, loan_id) =>
      (state.occupation_information.loan_id = loan_id),

   updatePercent: (state, percent) => (state.completed_percent = percent),

   occupationDetails: (state, responseData) => {
      state.occupation_information = {
         "stage" : responseData.stage,
         "position" : responseData.position,
         "own_business_name" : responseData.own_business_name,
         "occupation_phone" : responseData.occupation_phone,
         "years_of_service" : responseData.years_of_service,
         "months_of_service" : responseData.months_of_service,
         "monthly_basic_income" : responseData.monthly_basic_income,
         "additional_income" : responseData.additional_income,
         "total_income" : responseData.total_income,
      },

      state.company_address = {
         "country_state_id": responseData.occupation_address?.country_state_id,
         "city_id": responseData.occupation_address.city_id,
         "township_id": responseData.occupation_address.township_id,
         "place_type" : "Ward",
         "ward_or_village_name" : responseData.occupation_address.ward_or_village_name,
         "street": responseData.occupation_address.street,
         "building_number": responseData.occupation_address.building_number,
         "floor": responseData.occupation_address.floor
      }
   },

   addCompanyRegionData: (state, payload) => {
      state.company_address.country_state_id = payload?.id;
   },
   addCompanyCityData: (state, payload) => {
      state.company_address.city_id = payload?.id;
   },
   addCompanyTownshipData: (state, payload) => {
      state.company_address.township_id = payload?.id;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};